<template>
  <v-form ref="formRef">
    <div v-for="(payment, index) in payments" :key="payment.id" class=" mb-4">
      <div class="d-flex justify-space-between align-center gap-3">
        <div style="width: 170px">
          <chip-dropdown
            v-model="payment.kind"
            :color="getChipBackgroundColorByKind(payment.kind)"
            :items="paymentMethods"
            item-label="text"
            item-value="kind"
            @change="onChangeKind(index)"
          ></chip-dropdown>
        </div>

        <div class="flex-1 d-flex gap-3">
          <tp-input-price
            v-model="payment.amount"
            :label="
              payment.kind === LIST_PAYMENT_KIND.QUET_THE.ID ||
              payment.kind === LIST_PAYMENT_KIND.TRA_GOP.ID
                ? 'Số tiền chưa bao gồm phí'
                : 'Số tiền'
            "
            dense
            flat
            hide-details="auto"
            outlined
          ></tp-input-price>

          <tp-input-price
            v-if="
              payment.kind === LIST_PAYMENT_KIND.QUET_THE.id ||
                payment.kind === LIST_PAYMENT_KIND.TRA_GOP.id
            "
            v-model="payment.payment_fee"
            :validate="payment.amount > 0 ? 'require' : ''"
            dense
            flat
            hide-details="auto"
            label="Phí thanh toán"
            outlined
          ></tp-input-price>

          <tp-select
            v-if="
              payment.kind === LIST_PAYMENT_KIND.QUET_THE.id ||
                payment.kind === LIST_PAYMENT_KIND.TRA_GOP.id ||
                payment.kind === LIST_PAYMENT_KIND.COD.id
            "
            v-model="payment.receiverable_id"
            :items="receiverableByPaymentKind(payment.kind)"
            :validate="payment.amount > 0 ? 'required' : ''"
            hide-details="auto"
            item-text="name"
            item-value="id"
            label="Đối tác"
            placeholder="Chọn đối tác"
            solo
          ></tp-select>

          <v-select
            v-if="payment.kind === LIST_PAYMENT_KIND.CHUYEN_KHOAN.id"
            v-model="payment.receiver_bank_id"
            :items="companyBankAccounts"
            :menu-props="{ nudgeTop: '-40px' }"
            :rules="
              payment.amount > 0 ? [v => !!v || 'Không được bỏ trống!'] : []
            "
            dense
            flat
            hide-details="auto"
            item-text="name"
            item-value="id"
            outlined
            placeholder="Tài khoản nhận"
            solo
          >
            <template #selection="data">
              <div style="max-width: 100%">
                <div class="text-truncate">
                  {{ data.item.number }} - {{ data.item.name }} ({{
                    data.item.bank_code
                  }})
                </div>
              </div>
            </template>
            <template #item="data">
              <div class="py-1">
                <div class="text-body-1 font-weight-bold">
                  {{ data.item.number }} - {{ data.item.name }}
                </div>
                <div class="text-body-2">{{ data.item.bank_code }}</div>
              </div>
            </template>
          </v-select>

          <tp-select
            v-if="payment.kind === LIST_PAYMENT_KIND.QUET_THE.id"
            v-model="payment.pos_id"
            :items="filteredPosDevices"
            :validate="payment.amount > 0 ? 'require' : ''"
            hide-details="auto"
            item-text="name"
            item-value="name"
            no-data-text="Chọn chi nhánh trước"
            placeholder="Chọn máy POS"
            solo
          ></tp-select>
        </div>

        <div style="width: 100px">
          <v-btn
            v-if="index === 0"
            color="primary"
            text
            @click="handleAddNewPayment"
          >
            <v-icon left>mdi-plus</v-icon>
            Thêm
          </v-btn>

          <v-btn
            v-else
            color="red"
            text
            @click="handleRemovePayment(payment.id)"
          >
            <v-icon left>mdi-minus</v-icon>
            Xoá
          </v-btn>
        </div>
      </div>

      <div
        v-if="
          modelId &&
            payment.kind === LIST_PAYMENT_KIND.CHUYEN_KHOAN.id &&
            isSupportQr(payment.receiver_bank_id)
        "
        :key="payment.receiver_bank_id + payment.amount + modelId"
      >
        <form-item-qr-code
          :model-id="modelId"
          :model-type="modelType"
          :payment="payment"
          class="mt-4 pa-4"
          @update:qrId="$set(payment, 'qr_id', $event)"
        ></form-item-qr-code>
      </div>
    </div>
  </v-form>
</template>

<script>
import ChipDropdown from "./components/ChipDropdown.vue";
import {
  LIST_PAYMENT_KIND,
  PAYMENT_DEFAULT_VALUE,
  PAYMENT_KIND_KEY
} from "@/core/constant";
import { LIST_SUPPLIER_CATEGORIES } from "@/modules/PartnerAndMember/constant";
import FormItemQrCode from "./form-item-qr-code.vue";

export default {
  components: {
    FormItemQrCode,
    ChipDropdown
  },

  props: {
    branchId: {
      type: Number,
      require: true
    },
    paymentCod: {
      type: Boolean,
      default: false
    },
    paymentTraGop: {
      type: Boolean,
      default: false
    },
    modelType: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      LIST_PAYMENT_KIND: LIST_PAYMENT_KIND,
      paymentPartner: [],
      shippingPartner: [],
      installmentPartner: [],
      payments: [
        {
          kind: LIST_PAYMENT_KIND.CHUYEN_KHOAN.id,
          amount: 0,
          id: 0
        }
      ],
      formRef: null
    };
  },

  async created() {
    await this.$store.dispatch("SALE/getPosDevices");

    this.paymentPartner = await this.$store.dispatch(
      "SUPPLIER/getAllSuppliers",
      `?sup_cate_ids=${LIST_SUPPLIER_CATEGORIES[3].id}`
    );

    this.shippingPartner = await this.$store.dispatch(
      "SUPPLIER/getAllSuppliers",
      `?sup_cate_ids=${LIST_SUPPLIER_CATEGORIES[2].id}`
    );

    this.installmentPartner = await this.$store.dispatch(
      "SUPPLIER/getAllSuppliers",
      `?sup_cate_ids=${LIST_SUPPLIER_CATEGORIES[4].id}`
    );

    await this.$store.dispatch("BANK_ACCOUNT/getCompanyBankAccounts");
  },

  computed: {
    paymentMethods() {
      const DEFAULT_PAYMENT_METHODS = [
        {
          kind: this.LIST_PAYMENT_KIND.TIEN_MAT.id,
          text: "Tiền mặt"
        },
        {
          kind: this.LIST_PAYMENT_KIND.CHUYEN_KHOAN.id,
          text: "Chuyển khoản"
        },
        {
          kind: this.LIST_PAYMENT_KIND.QUET_THE.id,
          text: "Quẹt thẻ"
        }
      ];

      if (this.paymentCod) {
        DEFAULT_PAYMENT_METHODS.push({
          kind: this.LIST_PAYMENT_KIND.COD.id,
          text: "COD"
        });
      }

      if (this.paymentTraGop) {
        DEFAULT_PAYMENT_METHODS.push({
          kind: this.LIST_PAYMENT_KIND.TRA_GOP.id,
          text: "Trả góp"
        });
      }

      return DEFAULT_PAYMENT_METHODS;
    },

    posDevices() {
      return this.$store.getters["SALE/posDevices"];
    },

    filteredPosDevices() {
      if (!this.branchId) return [];

      return this.posDevices.filter(
        device => device.branchId === this.branchId
      );
    },

    companyBankAccounts() {
      const accounts = this.$store.getters["BANK_ACCOUNT/companyBankAccounts"];
      const filteredAccounts = accounts
        .filter(account => account.enable)
        .filter(account => account.functions.includes("THANH TOAN"));

      if (!this.branchId) return filteredAccounts;

      return filteredAccounts.filter(account => {
        if (!account.branches) return false;

        return account.branches.includes(this.branchId);
      });
    }
  },

  methods: {
    isSupportQr(bankId) {
      const BANK_CODE_SUPPORT_QR = "VCB";

      const activatedBankAccount = this.companyBankAccounts.find(
        bank => bank.id === bankId
      );

      if (!activatedBankAccount) return false;

      return activatedBankAccount.bank_code === BANK_CODE_SUPPORT_QR;
    },
    getUniqueId() {
      return Math.floor(Math.random() * 1000000) + "-" + Date.now();
    },

    receiverableByPaymentKind(kind) {
      if (kind === LIST_PAYMENT_KIND.QUET_THE.id) {
        return this.paymentPartner;
      }

      if (kind === LIST_PAYMENT_KIND.COD.id) {
        return this.shippingPartner;
      }

      if (kind === LIST_PAYMENT_KIND.TRA_GOP.id) {
        return this.installmentPartner;
      }
    },

    handleAddNewPayment() {
      this.payments.push({
        kind: LIST_PAYMENT_KIND.CHUYEN_KHOAN.id,
        amount: 0,
        id: this.getUniqueId()
      });
    },

    handleRemovePayment(id) {
      this.payments = this.payments.filter(item => item.id !== id);
    },

    getChipBackgroundColorByKind(kind) {
      if (kind === LIST_PAYMENT_KIND.QUET_THE.id) return "green lighten-4";
      if (kind === LIST_PAYMENT_KIND.CHUYEN_KHOAN.id) return "yellow lighten-4";
      if (kind === LIST_PAYMENT_KIND.TIEN_MAT.id) return "blue lighten-4";
      if (kind === LIST_PAYMENT_KIND.COD.id) return "red lighten-4";
      if (kind === LIST_PAYMENT_KIND.TRA_GOP.id) return "purple lighten-4";

      return "";
    },
    onChangeKind(index) {
      // reset phí thanh toán khi thay đổi loại chuyển khoản
      this.$set(this.payments[index], "payment_fee", 0);
    }
  },

  watch: {
    payments: {
      handler(value) {
        const transformedValue = value.map(item => {
          return {
            ...PAYMENT_DEFAULT_VALUE[PAYMENT_KIND_KEY[item.kind]],
            ...item
          };
        });

        this.$emit("change", transformedValue);
      },
      deep: true
    },
    paymentCod() {
      this.payments = this.payments.filter(
        payment => payment.kind !== LIST_PAYMENT_KIND.COD.id
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.gap-3 {
  gap: 12px;
}
</style>
