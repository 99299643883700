<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-chip :color="color" class="black--text" v-bind="attrs" v-on="on">
        {{ getLabelOfValue }}

        <v-icon :color="darkenColor" right>
          mdi-menu-down
        </v-icon>
      </v-chip>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="onSelected(item, index)"
      >
        <v-list-item-title>{{ item[itemLabel] }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      require: true
    },

    itemLabel: {
      type: String,
      default: "label"
    },

    itemValue: {
      type: String,
      default: "value"
    },

    value: {
      type: Number,
      require: true
    },

    color: {
      type: String,
      default: "blue lighten-4"
    }
  },

  computed: {
    getLabelOfValue() {
      const findedItem = this.items.find(
        item => item[this.itemValue] === this.value
      );

      return findedItem ? findedItem[this.itemLabel] : "";
    },

    darkenColor() {
      return `${this.color.split(" ")[0]} darken-3`;
    }
  },

  methods: {
    onSelected(item, index) {
      this.$emit("input", item[this.itemValue]);
      this.$emit("change", item, index);
    }
  }
};
</script>

<style lang="scss" scoped></style>
