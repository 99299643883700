<template>
  <div v-if="isCreateQR" style="background: #F5F5F5">
    <div class="flex">
      <button
        class="d-flex align-center py-1 px-2 white"
        @click.stop.prevent="onCheckQrStatus"
      >
        <v-icon small>mdi-sync</v-icon>

        <span class="ml-1 primary--text">Kiểm tra trạng thái</span>
      </button>
    </div>

    <div v-if="!isSuccess" class="mt-4 d-flex flex-col align-center">
      <div style="width: 200px; height: 200px">
        <img :src="data.qr_image" alt="" class="w-full h-full" />
      </div>

      <div class="mt-2 d-inline-flex">
        <span>
          Quét mã QR bên dưới để thanh toán hoặc chuyển khoản với nội dung:
          <span class="font-weight-bold">{{ data.qr_content }}</span>
        </span>
      </div>
      <div class="mt-1 d-inline-flex">
        <span>
          Hệ thống sẽ mất khoảng 30s để xác nhận giao dịch ngay sau khi chuyển
          khoản thành công.
        </span>
      </div>
    </div>

    <div v-else class="mt-4 d-flex flex-col align-center">
      <svg
        fill="none"
        height="200"
        viewBox="0 0 151 150"
        width="200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="white" height="150" rx="75" width="150" x="0.449219" />
        <path
          d="M105.012 56.1895L67.3867 93.8128L48.5742 75.002"
          stroke="#63B76B"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="16"
        />
        <rect height="85" stroke="white" width="85" x="32.9492" y="32.5" />
      </svg>

      <div class="mt-2 d-inline-flex">
        <span>
          Đã nhận được
          <span class="font-weight-bold">
            {{ payment.amount | formatCurrency }}</span
          ></span
        >
      </div>
    </div>
  </div>

  <div v-else style="background: #F5F5F5">
    <span
      >Nếu khách chuyển khoản bằng quét QR, bấm tạo QR bên dưới. Trường hợp
      khách chuyển khoản thường hoặc muốn kế toán xác nhận giao dịch, bạn có thể
      bỏ qua phần này.</span
    >

    <div class="mt-3">
      <button
        class="flex align-center justify-center white rounded pa-2 white w-full"
        @click.stop.prevent="onCreateQR"
      >
        <v-icon class="primary--text">mdi-plus</v-icon>

        <span class="ml-1 primary--text font-weight-medium">Tạo QR</span>
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, reactive, ref } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { tryOnScopeDispose } from "@vueuse/core";

import BankAccountService from "@/core/service/bank-account.service";

const { getBankAccountQR, checkBankAccountQr } = BankAccountService;

export default defineComponent({
  props: {
    payment: {
      type: Object,
      required: true
    },
    modelType: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    }
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const socket = instance.proxy.$socket;

    const isCreateQR = ref(false);
    const isSuccess = ref(false);

    const state = reactive({
      bank_account_id: props.payment.receiver_bank_id,
      amount: props.payment.amount,
      model_type: props.modelType,
      model_id: props.modelId
    });

    const { fetch, data } = useFetchData(() => getBankAccountQR(state), {
      transform: response => response.data
    });

    const handleSuccess = () => {
      isSuccess.value = true;
    };

    const onCheckQrStatus = async () => {
      try {
        const { data: response } = await checkBankAccountQr(data.value.qr_id);

        if (response.status === "SUCCESS") {
          handleSuccess();
        } else {
          instance.proxy.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: "Chưa nhận được tiền"
            }
          });
        }
      } catch (e) {
        console.log("checkQrStatus", e);
      }
    };

    const onCreateQR = async () => {
      await fetch();

      isCreateQR.value = true;
      emit("update:qrId", data.value.qr_id);
      onSocketQr();
    };

    const onSocketQr = () => {
      if (socket.disconnected) {
        socket.connect();
      }

      socket.once(`qr.${data.value.qr_id}`, () => {
        handleSuccess();
      });
    };

    tryOnScopeDispose(() => {
      if (data.value) {
        socket.off(`qr.${data.value.qr_id}`);

        if (Object.keys(socket._callbacks).length === 0) {
          socket.disconnect();
        }
      }
    });

    return {
      isSuccess,
      isCreateQR,
      state,
      fetch,
      data,
      onCreateQR,
      onCheckQrStatus
    };
  }
});
</script>
